import styled from 'styled-components'


export const Container = styled.div`

  grid-column:2;
  grid-row:2;
`;

export const ButtonGroup = styled.div`
    display: flex;
    flex:1;
    justify-content: space-between;
    max-width: 700px;
    button{
        margin-left: 5px;
    }

`;

export const Area = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding:10px;
  max-width: 700px;
  border-radius: 8px;
`;