import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  height: calc(100vh - 60px);
  padding: 0 10px;

  @media (max-width: 768px) {
   
  }
`;




