import styled from 'styled-components'


export const Container = styled.div`
  grid-row-start:2;
  grid-row-end:3;
  grid-column-start:2;
  grid-column-end:2;
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content:center;

`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 20px;
  margin-top: 10px;
  border-radius: 8px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  >h3{
    align-self: center;
    margin-bottom: 10px;
  }

  > div {
      padding: 10px;
      margin-top:10px;
      border: 1px solid;
      border-radius: 8px;
    }
`;

export const Property = styled.span`
  font-weight: 700;
  color: #000;
`;

export const Description = styled.span`
  font-weight: 400;
  color: #333;
`;