import React, {useState, useEffect} from 'react'
import jexcel from "jexcel";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

import './style.css'
import api from '../../services/api'

import {Container} from './styles'

function Forecast() {
  const [forecast, setForecast] = useState([])
  const [isRefresh, setIsRefresh]=useState(true)


  useEffect(()=>{
  async function getForecast(){
    setIsRefresh(true)
    const response = await api.get('/forecast')
      setForecast(response.data)
      setIsRefresh(false)
  }
  getForecast()
},[])

if(!isRefresh) {

  const table = jexcel(document.getElementById('spreadsheet'), {
    data: forecast,
    minDimensions: [4, 1],
    columns: [
      { title:'Status', width:100, type:'text' },
        { title:'Oportunidade', width:100, type:'numeric' },
        { title:'Cod Cliente', width:100, type:'text' },
        { title:'Cliente', width:200, type:'text' },
        { title:'Cod Produto', width:100, type:'text' },
        { title:'Produto', width:200, type:'text' },
        { title:'Estágio', width:100, type:'text' },
        { title:'Título', width:500, type:'text' },
        { title:'Fechamento', width:120,type:'calendar' },
        { title:'Ano',  type:'numeric' },
        { title:'Mes',  type:'numeric' },
        { title:'Qtd', type:'numeric' },
        { title:'Valor', width:150, type:'number', mask:'R$ #,00', align:'left', decimal:","},
  ],
  toolbar:[
    {
        type: 'i',
        content: 'undo',
        onclick: function() {
          table.undo();
        }
    },
    {
        type: 'i',
        content: 'redo',
        onclick: function() {
          table.redo();
        }
    },
    {
        type: 'i',
        content: 'save',
        onclick: function () {
          table.download();
        }
    },
    {
        type: 'i',
        content: 'cloud_download',
        onclick: function () {
          document.getElementById("test-table-xls-button").click()
          
        }
    },
    {
        type: 'select',
        k: 'font-family',
        v: ['Arial','Verdana']
    },
    {
        type: 'select',
        k: 'font-size',
        v: ['9px','10px','11px','12px','13px','14px','15px','16px']
    },
    {
        type: 'i',
        content: 'format_align_left',
        k: 'text-align',
        v: 'left'
    },
    {
        type:'i',
        content:'format_align_center',
        k:'text-align',
        v:'center'
    },
    {
        type: 'i',
        content: 'format_align_right', 
        k: 'text-align',
        v: 'right'
    },
    {
        type: 'i',
        content: 'format_bold',
        k: 'font-weight',
        v: 'bold'
    },
    {
        type: 'color',
        content: 'format_color_text',
        k: 'color'
    },
    {
        type: 'color',
        content: 'format_color_fill',
        k: 'background-color'
    },
  ],
  text:{
  noRecordsFound:'Nenhum registro encontrado',
  showingPage:'Mostrando página {0} de {1} entradas',
  show:'Show',
  entries:'entradas',
  insertANewColumnBefore:'Inserir uma nova coluna antes de',
  insertANewColumnAfter:'Inserir uma nova coluna depois de',
  deleteSelectedColumns:'Excluir colunas selecionadas',
  renameThisColumn:'Renomear esta coluna',
  orderAscending:'ordem ascendente',
  orderDescending:'Order decrescente',
  insertANewRowBefore:'Inserir uma nova linha antes de',
  insertANewRowAfter:'Inserir uma nova linha depois de',
  deleteSelectedRows:'Excluir linhas selecionadas',
  editComments:'Editar comentários',
  addComments:'Adicionar comentários',
  comments:'Comentários',
  clearComments:'Limpar comentários',
  copy:'Copiar ...',
  paste:'Colar ...',
  saveAs:'Salvar como ...',
  about: 'Sobre',
  areYouSureToDeleteTheSelectedRows:'Tem certeza de excluir as linhas selecionadas?',
  areYouSureToDeleteTheSelectedColumns:'Tem certeza de excluir as colunas selecionadas?',
  thisActionWillDestroyAnyExistingMergedCellsAreYouSure:'Esta ação irá destruir todas as células mescladas existentes. Você tem certeza?',
  thisActionWillClearYourSearchResultsAreYouSure:'Esta ação limpará seus resultados de pesquisa. Você tem certeza?',
  thereIsAConflictWithAnotherMergedCell:'Há um conflito com outra célula mesclada',
  invalidMergeProperties:'Propriedades mescladas inválidas',
  cellAlreadyMerged:'Cell já mesclado',
  noCellsSelected:'Nenhuma célula selecionada',
  },
  allowDeleteColumn: false,
  allowRenameColumn: false,
  allowComments: false,
  columnResize: false,
  allowDeleteRow: false,
  allowInsertRow: false,
  allowInsertColumn: false,
  tableOverflow: true,
  tableWidth: "100%",
  tableHeight: "95%",
  csvFileName: "Forecast",
  about: false,
  fullscreen: true,
  wordWrap: true,
  columnSorting:true,
  editable:false,
  filters:true,
  });
  table.table.setAttribute('id', 'table')
}

  return (
<>
            <ReactHTMLTableToExcel
            id="test-table-xls-button"
            className="download-table-xls-button"
            table='table'
            filename="Forecast"
            sheet="forecast"
            buttonText="Download XLS"
            />
    
    <Container>
    <div id="spreadsheet"></div>
  </Container>
  </>  
  )
}

export default Forecast