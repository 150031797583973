import React from 'react';

import { Container } from './styles';


const Button = ({ children,color, ...rest }) => (
  <Container color={color} type="button" {...rest}>
    {children}
  </Container>
);

export default Button;