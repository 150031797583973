import React, {useState, useEffect} from 'react'
import {FiX, FiCheck, FiEdit, FiPlus} from 'react-icons/fi'
import {useNavigate} from 'react-router-dom'

import api from '../../../services/api'


import {Container, Action, Button, ListUser, User} from './styles'

function Users() {
  const navigate = useNavigate()
  const [users, setUsers] = useState([])
 
  async function getUsers(){
    const response = await api.get('/users')
    setUsers(response.data)
  }

  useEffect(()=>{ 
    getUsers()
  },[])

  async function inative(user, status){
    if(window.confirm(`Deseja ${status === 0? "Ativar" : "Inativar"} o usuário ${user.name}`)){
      await api.delete(`/users/${user.id}`)
      getUsers()
    }
  }
  
  return (
    <Container>
      <Action>
        <Button onClick={()=> navigate('create')}><FiPlus/></Button>
      </Action>
      <ListUser>
        {users.map(user =>(
          <User key={user.id}>
            <div className="info">
            <strong>
                {user.name}
            </strong>
            <span>
              {user.email}
            </span>
            <span>
              {user.profile === 'Admin'? "Administrador" : "Usuário"}
            </span>
            </div>
            
            <div className="buttons">
            {user.active === 0? <Button color="#5cb85c" onClick={()=> inative(user, user.active ? 1 : 0)}><FiCheck/></Button> : <Button onClick={()=> inative(user, user.active ? 1 : 0)} color="#e02041"><FiX/></Button>}
            <Button onClick={()=> navigate(`update`, {state: user})}><FiEdit/></Button>
            </div>
          </User>
        ))}
      </ListUser>
    </Container>
    
    
  )
}

export default Users