import React, { createContext, useCallback, useState, useContext } from 'react';
import api  from '../services/api';

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [data, setData] = useState(() => {
    const token = localStorage.getItem('@IntelligenzaWeb:token');
    const user = localStorage.getItem('@IntelligenzaWeb:user');

    if (token && user) {
      return { token, user: JSON.parse(user) };
    }
    return {}
  });
  const signIn = useCallback(async ({ email, password }) => {
    const response = await api.post('/session', {
      email,
      password,
    });
    const { token, user } = response.data;
    localStorage.setItem('@IntelligenzaWeb:token', token);
    localStorage.setItem('@IntelligenzaWeb:user', JSON.stringify(user));
    setData({ token, user });
  }, []);

  const forgotPassword = useCallback(async ({ email }) => {
    await api.post('password', {
      email,
      redirect_url: 'https://pipedrive.intelligenzait.com/reset',
    });
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem('@IntelligenzaWeb:token');
    localStorage.removeItem('@IntelligenzaWeb:user');
    setData({});
  }, []);

  return (
    <AuthContext.Provider
      value={{ user: data.user, signIn, signOut, forgotPassword }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}