import styled from 'styled-components'


export const Container = styled.div`
grid-column:2;
  grid-row:3;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  >h3{
    align-self: center;
    margin-bottom: 10px;
  }
`;

export const Error = styled.span`
  font-weight: 400;
  color: #c53030;
  align-self: center;
  margin-top: 10px;
`;