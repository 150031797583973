import React, {useState, useEffect, useRef} from 'react';
import {FiMail, FiUser} from 'react-icons/fi'
import {useNavigate} from 'react-router-dom'
import api from '../../../services/api'

import { Container, Form, Error} from './styles';

import Input from '../../../components/Input'
import Button from '../../../components/Button'



function CreateUser() {
  const navigate = useNavigate()
  const nameRef = useRef(null)
  const mailRef = useRef(null)

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [admin, setAdmin] = useState(false)
  const [isError, setIsError] = useState('')
  


  useEffect(()=>{
      nameRef.current.focus()
  
  },[])

  async function handleSubmit(e){
    e.preventDefault()
      try {
        if(name!=='' && email !==''){
          if(window.confirm('Todoas as informações estão corretas?')){
            await api.post('/users', {email, name, profile: Number(admin) === 1 ? 'Admin' : 'User'})
            navigate('/users')
          }else{
            nameRef.current.focus()
          }
        }else{
          setIsError('Campos Obrigatórios')  
        }
      } catch (error) {
        setIsError(error.response.data[0].message)
      }
  }
  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        <h3>Novo Usuário</h3>
        <Input  icon={FiUser}
        inputRef={nameRef}
        type="text" 
        placeholder="Nome" 
        value={name} 
        onChange={e => setName(e.target.value)}/>

        <Input  icon={FiMail}
        inputRef={mailRef}
        type="email" 
        placeholder="Email" 
        value={email} 
        onChange={e => setEmail(e.target.value)}/>

        <select value={admin} onChange={e => setAdmin(e.target.value)}>
          <option value={0}>Usuário</option>
          <option value={1}>Administrador</option>
        </select>

        {isError !== '' ? <Error>{isError}</Error> : ""}
        <Button type="submit">Cadastrar</Button>
        </Form>
    </Container>
  )
}

export default CreateUser;