import React from 'react';
import {Routes} from 'react-router-dom'
import Route from './Route'

import Login from '../pages/Login/Login'
import ForgotPassword from '../pages/Login/ForgotPassword'
import ResetPassword from '../pages/Login/ResetPassword'

import Deal from '../pages/Deal/Deal'
import Detail from '../pages/Deal/DealDetail'
import Parcel from '../pages/Deal/Parcel'
import UpdateParcel from '../pages/Deal/UpdateParcel'
import Forecast from '../pages/Forecast'

import Users from '../pages/Users/Users'
import CreateUser from '../pages/Users/CreateUser'
import UpdateUser from '../pages/Users/UpdateUser'
import Profile from '../pages/Users/Profile'

import NotFound from '../pages/NotFound'

function MainRoutes() {
  return (
    <Routes>
      <Route path="" element={<Login/>} noHeader/>
      <Route path="forgot" element={<ForgotPassword/>} noHeader/>
      <Route path="reset/:token" element={<ResetPassword/>} noHeader/>

      <Route path="deal" element={<Deal/>} isPrivate/>
      <Route path="deal/detail" element={<Detail/>} isPrivate/>
      <Route path="deal/create" element={<Parcel/>} isPrivate/>
      <Route path="deal/update" element={<UpdateParcel/>} isPrivate/>
      
      <Route path="forecast" element={<Forecast/>} isPrivate isAdmin/>
      
      <Route path="users" element={<Users/>} isPrivate isAdmin/>
      <Route path="users/create" element={<CreateUser/>} isPrivate isAdmin/>
      <Route path="users/update" element={<UpdateUser/>} isPrivate isAdmin/>
      
      <Route path="profile" element={<Profile/>} isPrivate/>
      
      <Route path="*" element={<NotFound/>} noHeader notFound/>
    </Routes>
  )
} 

export default MainRoutes;