import styled from 'styled-components'
import {Link as LinkDom} from 'react-router-dom'


export const Container = styled.div`
  grid-column:2;
  grid-row:3;
  @media(max-height: 300px){
    grid-row-start:1;
    grid-row-end:2;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  transition: ease all .2s;
  flex-wrap: wrap;
  
  @media(max-height: 300px){
    padding: 10px;
    img{
      display: none
    }
  }
`;

export const Logo = styled.img`
  height: 60px;
  margin-bottom: 20px;
`;

export const Error = styled.span`
  font-weight: 400;
  color: #c53030;
  align-self: center;
  margin-top: 10px;
`;

export const Link = styled(LinkDom)`
  font-weight: 700;
  color: #2695c2;
  text-decoration: none;
  align-self: center;
  margin-top: 10px;
  display: flex;
  align-items: center;

  svg{
    margin-right: 10px;
  }
`;