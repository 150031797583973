import React, {useState, useRef, useEffect} from 'react';
import {FiLock} from 'react-icons/fi'
import api from '../../../services/api'

import Input from '../../../components/Input'
import Button from '../../../components/Button'

import { Container, Form, Error} from './styles';

function Profile() {

  const [password, setPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [password_confirmation, setPassword_confirmation] = useState('')
  const [isError, setIsError] = useState('')

  const passwordRef = useRef(null)
  const newPasswordRef = useRef(null)
  const passwordConfirmationRef = useRef(null)

  useEffect(()=>{
    passwordRef.current.focus()
  },[])

  async function handleSubmit(e){
    e.preventDefault()
    if(newPassword === password_confirmation){
      try {
        await api.put(`/change_password`,{ password, newPassword, password_confirmation })
        alert('Senha atualizada com sucesso!')
        setPassword('')
        setNewPassword('')
        setPassword_confirmation('')
      } catch (error) {
        setIsError(error.response.data[0].message)
      }
    }else{
      setIsError('As senhas não conferem.')
    }
  }

  return (
    <Container>
        <Form onSubmit={handleSubmit}>
            <h3>Trocar senha</h3>
            <Input 
            icon={FiLock} 
            inputRef={passwordRef}
            type="password" 
            placeholder="Senha antiga"
            value={password}
            onChange={e => setPassword(e.target.value)}
            />
            <Input 
            inputRef={newPasswordRef}
            icon={FiLock} 
            type="password" 
            placeholder="Nova Senha"
            value={newPassword}
            onChange={e => setNewPassword(e.target.value)}
            />
            <Input 
            icon={FiLock} 
            inputRef={passwordConfirmationRef}
            type="password" 
            placeholder="Confirmação"
            value={password_confirmation}
            onChange={e => setPassword_confirmation(e.target.value)}
            />
            {isError !== '' ? <Error>{isError}</Error> : ""}

            <Button type="submit">Salvar</Button>
        </Form>
    </Container>
  )
}

export default Profile;