import React, {useState, useCallback, useEffect} from 'react';
import {useLocation, useNavigate} from 'react-router-dom'
import Spreadsheet, {createEmptyMatrix} from 'react-spreadsheet'
import * as Matrix  from 'react-spreadsheet/dist/matrix'

import api from '../../../services/api'
import Button from '../../../components/Button'

import { Container, Area, ButtonGroup } from './styles';

function Parcel() {
  const navigate = useNavigate()
  const location = useLocation();
  const deal = location.state;
  const labelColumns = ['Produto', 'Parcela', 'Dias', 'Valor'];
  const initialData = createEmptyMatrix(1, 4);
  const [data, setData] = useState(initialData);


  useEffect(()=>{
    const parcels = deal.parcels.map(parcel =>{
      return ([{value: parcel.code}, {value: parcel.parcel}, { value: parcel.days}, {value: parcel.value.toString().replace('.', ',')}])
    })
    setData(parcels)
  },[deal.parcels])

  function isNumber(value) {
    return typeof Number(value) === 'number' && isFinite(value);
 }

 function formData(data){
  const formmatedData = data.map(parcel => {
  return {
  code: parcel[0].value,
  parcel:parcel[1].value,
  days: parcel[2].value,
  value: parcel[3].value.replace('R$','').replace(/\./g,'').replace(',','.').trim()
  }
  })
  return formmatedData
}

  async function handleSubmit(){

    let productError = false;
    let numberField = false;
    let rowError = false;
    let sumParcel = 0;

    const parcels = data.map(parcel =>{
      if(parcel[0]?.value && parcel[1]?.value && parcel[2]?.value && parcel[3]?.value){
        const product = deal.products.find(product => product.code === parcel[0].value)
          if(product){
            return {code: parcel[0].value, parcel: isNumber(parcel[1].value)? parcel[1].value: numberField=true , days: isNumber(parcel[2].value)? parcel[2].value: numberField=true, valor: isNumber(parcel[3].value.replace('R$','').replace(/\./g,'').replace(',','.').trim())? parcel[3].value.replace('R$','').replace(/\./g,'').replace(',','.').trim() : numberField=true};
          }else{
            productError= parcel[0].value;
            return null
          }
      }else{
        rowError=true;
        return null
      }
    })

    if(!rowError){
      if(!productError){
        if(!numberField){
          sumParcel = parcels.reduce(function (acc, obj) { return acc + Number(obj.valor) }, 0)
        if(sumParcel !== deal.value){
          alert('A soma das parcelas não correspondem ao valor da oportunidade.')
        }else{
          try {
            await api.put('/deal', {
              deal: deal.deal,
              title: deal.title,
              status: deal.status,
              stage: deal.stage,
              client_id: deal.client_id,
              client: deal.client,
              value: deal.value,
              expected_close_date: deal.expected_close_date,
              products: deal.products,
              parcels: formData(data)
            })
            alert('Parcelas cadastradas com sucesso.')
            navigate('/deal')
          } catch (error) {
            console.log(error)
          }
        }
        } else{
          alert('Os campos devem conter números')
        }
      }else{
        alert(`O produto de código ${productError} não pertence a esta oportunidade.`)
      }
    }else{
      alert('Todos os campos da planilha devem ser preenchidos.')
    }

    
  }

  const addRow = useCallback(
    () =>
      setData(data => {
        const { columns } = Matrix.getSize(data);
        return [...data, Array(columns)];
      }),
    []
  );

  const removeRow = useCallback(() => {
    if (data.length>1){
      setData(data => {
        return data.slice(0, data.length - 1);
      });
    }
  }, [data.length]);



return (
  <Container>
    <ButtonGroup>
      <Button color="#5cb85c" onClick={addRow}>Adicionar</Button>
      <Button color="#c53030" onClick={removeRow}>Remover</Button>
      <Button color="#f0ad4e" onClick={()=> setData(initialData)}>Limpar</Button>
      <Button color="#aaaaaa" onClick={()=> navigate('/deal')}>Voltar</Button>
    </ButtonGroup>
   
   <Area>
    <Spreadsheet data={data} onChange={setData}  hideRowIndicators columnLabels={labelColumns} />
    <Button onClick={handleSubmit}>Salvar</Button>
   </Area>

  </Container>
)
}

export default Parcel;