import React from 'react';
import {FiHome} from 'react-icons/fi'



import { Container, Form, Logo, Link } from './styles';

import logoitz from '../../assets/logo.png'

function NotFound() {

  
  return (
    <Container>
      <Form >
        <Logo src={logoitz} alt="Logo"/>
        <h1>Esta página não existe</h1>
        <Link to="/"><FiHome/>Ir para a Home</Link>
        </Form>
    </Container>
  )
}

export default NotFound;