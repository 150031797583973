import React, {useState, useRef, useEffect} from 'react';
import {FiMail, FiLogIn} from 'react-icons/fi'
import {useNavigate} from 'react-router-dom'
import {useAuth} from '../../../hooks/auth'

import { Container, Form, Logo, Error, Link } from './styles';

import Input from '../../../components/Input'
import Button from '../../../components/Button'
import logoitz from '../../../assets/logo.png'

function ForgotPassword() {
  const navigate = useNavigate()
  const {forgotPassword} = useAuth()
  const [email, setEmail] = useState('')
  const [isError, setIsError] = useState('')
  const mailRef = useRef(null)

  async function handleSubmit(e){
    try {
      e.preventDefault()
      if(email !== '' ){
        await forgotPassword({email})
        alert('Verifique seu e-mail e siga os passos para resetar sua senha.')
        navigate('/')
      }else{
        setIsError('Campo Obrigatório')
      }
    } catch (error) {
      setIsError(error.response.data[0].message)
      setEmail('')
      mailRef.current.focus()
    }
  
  }

  useEffect(()=>{
    mailRef.current.focus()
  },[])
  
  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        <Logo src={logoitz} alt="Logo"/>
        <Input  icon={FiMail}
        inputRef={mailRef}
        type="email" 
        placeholder="Email" 
        value={email} 
        onChange={e => setEmail(e.target.value)}/>
        {isError !== '' ? <Error>{isError}</Error> : ""}
        <Button type="submit">Entrar</Button>
        <Link to="/"> <FiLogIn/>Voltar para logon</Link>
        </Form>
    </Container>
  )
}

export default ForgotPassword;