import styled, { css }from 'styled-components';
import { shade } from 'polished';


export const Container = styled.div`
  background: #fff;
  border-radius: 10px;
  padding: 16px;
  width: 100%;
  display: flex;
  align-items: center;
  color: #666360;
  border: 2px solid #8f969c;

  ${(props) =>
    props.isFocused &&
    css`
      color: #2695c2;
      border-color: #2695c2;
    `}

    ${(props) =>
    props.isFilled &&
    css`
      color: #2695c2;
    `}
  
  & + div {
    margin-top: 8px;
  }
  
  input {
    color: #333;
    flex: 1;
    background: transparent;
    border: 0;
    font-size: 20px;
    &::placeholder {
      color: ${shade(0.3, '#f4ede8')};
    }
  }
  svg {
    margin-right: 16px;
  }
`;