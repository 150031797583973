import React, {useState, useRef, useEffect} from 'react';
import {useNavigate} from 'react-router-dom'
import {FiFolderPlus} from 'react-icons/fi'
import { Container, Form, Error } from './styles';
import {parseISO, format} from 'date-fns'
import api from '../../../services/api'

import Input from '../../../components/Input'
import Button from '../../../components/Button'

function Deal() {
  const dealRef = useRef(null)
  const navigate = useNavigate()
  const [deal, setDeal] = useState('')
  const [isError, setIsError] = useState('')

  useEffect(()=>{
    dealRef.current.focus()
  },[])
 
  async function handleSubmit(e){
    e.preventDefault()
    if(deal !==''){
      try {
        const dealExists = await api.get(`/dealexists/${deal}`)
        if(dealExists.data){
          if(window.confirm('Já existem parcelas cadastradas para essa oportunidade, deseja continuar?')){
            navigate('detail', {state: {...dealExists.data, expected_close_date: format(parseISO(dealExists.data.expected_close_date.replace('T00:00:00.000Z','')),'yyy-MM-dd')}})
          }else{
            setDeal('')
            dealRef.current.focus()
            setIsError('')
          }
        }else{
          const dealResponse = await api.get(`/deal/${deal}`)
          if(dealResponse.data.value === 0 ||dealResponse.data.products.length===0 || dealResponse.data.expected_close_date===null){
            if(dealResponse.data.expected_close_date===null){
              setIsError('O campo expected_close_date deve ser preenchido no Pipedrive ')
              setDeal('')
              dealRef.current.focus()  
            }else if (dealResponse.data.products.length===0){
              setIsError('Não exitem produtos cadastrados para esta oportunidade')
              setDeal('')
              dealRef.current.focus() 
            }else{
              setIsError('O valor da oportunidade é Zero')
              setDeal('')
              dealRef.current.focus()
            }
          }else if(dealResponse.data.status!== 'open'){
            if(window.confirm('O status para essa oportunidade é diferente de open, deseja continuar?')){
              navigate('detail', {state: dealResponse.data})
            }else{
              setDeal('')
              dealRef.current.focus()
              setIsError('')
            }
          }else{
            navigate('detail', {state: dealResponse.data})
          }

        }
      } catch (error) {
        setIsError(error.response.data[0].message)
      }
      
    }else{
      setIsError('Campo Obrigatório')
    }
  }
  return (
  

      <Container>
        
        <Form onSubmit={handleSubmit}>
        <h3>Cadastro de parcelas</h3>
        <Input icon={FiFolderPlus} 
        type="number" 
        inputRef={dealRef}
        placeholder="Oportunidade" 
        value={deal} 
        onChange={e => setDeal(e.target.value)}/>
        {isError !== '' ? <Error>{isError}</Error> : ""}
        <Button type="submit">Entrar</Button>
        </Form>
      </Container>

    )
}

export default Deal;