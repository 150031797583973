import React, {useState, useRef, useEffect} from 'react';
import {FiMail, FiLock} from 'react-icons/fi'

import {useAuth} from '../../../hooks/auth'

import { Container, Form, Logo, Error, Link } from './styles';

import Input from '../../../components/Input'
import Button from '../../../components/Button'
import logoitz from '../../../assets/logo.png'

function Login() {
  const {signIn} = useAuth()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [isError, setIsError] = useState('')
  const mailRef = useRef(null)
  const passWordRef = useRef(null)

  async function handleSubmit(e){
    try {
      e.preventDefault()
      if(email !== '' && password!==''){
        await signIn({email, password})
      }else{
        setIsError('Campos Obrigatórios')
      }
    } catch (error) {
      setIsError(error.response.data[0].message)
      setEmail('')
      setPassword('')
      mailRef.current.focus()
    }
  }

  useEffect(()=>{
    mailRef.current.focus()
  },[])
  
  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        <Logo src={logoitz} alt="Logo"/>
        <Input  icon={FiMail}
        inputRef={mailRef}
        type="email" 
        placeholder="Email" 
        value={email} 
        onChange={e => setEmail(e.target.value)}/>

        <Input icon={FiLock} 
        type="password" 
        inputRef={passWordRef}
        placeholder="Password" 
        value={password} 
        onChange={e => setPassword(e.target.value)}/>
            {isError !== '' ? <Error>{isError}</Error> : ""}
        <Button type="submit">Entrar</Button>
        <Link to="forgot">Esqueci a senha</Link>
        </Form>
    </Container>
  )
}

export default Login;