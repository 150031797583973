import styled from 'styled-components'
import {NavLink} from 'react-router-dom'
import {shade} from 'polished'

export const Container = styled.div`

  grid-row-start:1;
  grid-row-end:2;
  grid-column-start:1;
  grid-column-end:4;

  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  background: #2695c2;
  padding: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
`;

export const GroupLink = styled.div`
  padding: 10px;
`;

export const Link = styled(NavLink)`
  color: #fff;
  text-decoration: none;
  transition: background-color 0.2s;
  padding: 10px;
  border-radius:6px;
 
 &:hover {
   background: ${shade(0.2, '#2695c2')};

 }

  & + a {
    margin-left: 10px;
  }

`;

export const Button = styled.button`

  color: #fff;
  font-weight: 700;
  background: transparent;
  border: none;
  transition: background-color 0.2s;
  padding: 10px;
  border-radius:6px;
 
 &:hover {
   background: ${shade(0.2, '#2695c2')};
   color: white;
 }
`;