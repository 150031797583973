import React, {useState, useRef, useEffect} from 'react';
import {FiLock, FiLogIn} from 'react-icons/fi'
import {useParams, useNavigate} from 'react-router-dom'
import api from '../../../services/api'

import { Container, Form, Logo, Error, Link } from './styles';

import Input from '../../../components/Input'
import Button from '../../../components/Button'
import logoitz from '../../../assets/logo.png'

function ResetPassword() {
  const navigate = useNavigate()
  const {token} = useParams()
  const [password, setPassword] = useState('')
  const [password_confirmation, setPasswordConfirmation] = useState('')
  const [isError, setIsError] = useState('')
  const passwordRef = useRef(null)

  async function handleSubmit(e){
    try {
      e.preventDefault()
      if(password !== '' && password_confirmation!==''){
        await api.put(`/reset/${token}`,{password, password_confirmation})
        alert('Você já pode fazer logon com sua nova senha.')
        navigate('/')
      }else{
        setIsError('Campos Obrigatórios')
      }
    } catch (error) {
      setIsError(error.response.data[0].message)
    }
  }

  useEffect(()=>{
    passwordRef.current.focus()
  },[])
  
  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        <Logo src={logoitz} alt="Logo"/>
        <Input  icon={FiLock}
        inputRef={passwordRef}
        type="password" 
        placeholder="Nova senha" 
        value={password} 
        onChange={e => setPassword(e.target.value)}/>

        <Input icon={FiLock} 
        type="password" 
        placeholder="Confirmar nova senha" 
        value={password_confirmation} 
        onChange={e => setPasswordConfirmation(e.target.value)}/>
            {isError !== '' ? <Error>{isError}</Error> : ""}
        <Button type="submit">Alterar Senha</Button>
        <Link to="/"> <FiLogIn/> Voltar para logon</Link>
        </Form>
    </Container>
  )
}

export default ResetPassword;