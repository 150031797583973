import React from 'react';
import {
  Navigate,
  Route as ReactDomRoute,
} from 'react-router-dom';

import { useAuth } from '../hooks/auth';
import Header from '../components/Header'

const Route = ({
  isPrivate = false,
  element,
  noHeader=false,
  isAdmin = false,
  notFound = false,
  path,
  ...rest
}) => {
  const {user} = useAuth()

  if(isPrivate === !!user){
    if(isAdmin){
      if(user?.profile==='Admin'){
        if(noHeader){
          return <ReactDomRoute path={path} element={element} {...rest} />
        }else{
          return (
          <>
          <Header/>
          <ReactDomRoute path={path} element={element} {...rest} />
          </>
          )
        }
      }else{
        if(notFound){
          return <ReactDomRoute path={path} element={element} {...rest} />
        }else{
          return <Navigate to={isAdmin ? '/' : '/deal'} />;
        }
      }
    }else{
      if(noHeader){
        return <ReactDomRoute path={path} element={element} {...rest} />
      }else{
        return (
        <>
        <Header/>
        <ReactDomRoute path={path} element={element} {...rest} />
        </>
        )
      }
    }
  }else{
      if(notFound){
        return <ReactDomRoute path={path} element={element} {...rest} />
      }else{
        return <Navigate to={isPrivate ? '/' : '/deal'} />;
      }
  }

};

export default Route;

