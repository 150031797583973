import axios from 'axios';

const api = axios.create({
  baseURL: 'https://api.intelligenzait.com',
  // baseURL: 'http://127.0.0.1:3333'
  
});

api.interceptors.request.use(
  async config => {
    const token = localStorage.getItem('@IntelligenzaWeb:token');
    config.headers.authorization = `Bearer ${token}`;
    return config;
  },
  error => Promise.reject(error),
);

export default api;