import React from 'react'
import {useAuth} from '../../hooks/auth'

import {Container,GroupLink, Link, Button} from './styles'

export default function Header() {

  const {user, signOut} = useAuth()

  if (user?.profile==="Admin"){
    return (
      <Container>
        <GroupLink>
        <Link to="/deal">Parcelas</Link>
        <Link to="/forecast">Forecast</Link>
        <Link to="/users">Usuários</Link>
        <Link to="/profile">Perfil</Link>
        </GroupLink>
        <Button onClick={()=> signOut()}>Sair</Button>
      </Container>
    ) 
  }else{
    return (
      <Container>
        <GroupLink>
        <Link to="/deal">Parcelas</Link>
        <Link to="/profile">Perfil</Link>
        </GroupLink>
        <Button onClick={()=> signOut()}>Sair</Button>
      </Container>
    ) 
  }

 
  
}
