import styled from 'styled-components'
import ButtonComponent from '../../../components/Button'


export const Container = styled.div`
  grid-column-start:1;
  grid-column-end:4;
  grid-row-start:2;
  grid-row-end:4;
`;


export const Action = styled.form`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100px;

  button{
    margin-right: 20px;
  }
  
`;

export const Button = styled(ButtonComponent)`
  width:100px;
`;

export const ListUser = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 10px;
  flex-wrap: wrap;

`;


export const User = styled.div`
    display: flex;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    background: #fff;
    margin: 20px 10px 0;
    border-radius: 6px;
    

    >div{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 10px;
      
    }
    .info{
      display: flex;
      align-items:flex-start;
      min-width: 300px;
    }
    .buttons{
      button{
        max-width:100px;
      }
    }


`;