import React from 'react';
import { useLocation, useNavigate} from 'react-router-dom'
import {format, parseISO} from 'date-fns'
import { Container, Form,Property, Description } from './styles';

import Button from '../../../components/Button'

function Detail() {
  const navigate = useNavigate()
  const location = useLocation()
  const deal = location.state
  return (
  <Container>
    <Form>
      <h3>Confira as informações da oportunidade</h3>
      <div>
      <Property>Oportunidade: </Property>
      <Description>{deal.deal}</Description>
      </div>
      <div>
      <Property>Título: </Property>
      <Description>{deal.title}</Description>
      </div>
      <div>
      <Property>Cliente: </Property>
      <Description>{deal.client}</Description>
      </div>
      <div>
      <Property>Estágio: </Property>
      <Description>{deal.stage}</Description>
      </div>
      <div>
      <Property>Status: </Property>
      <Description>{deal.status}</Description>
      </div>
      <div>
      <Property>Data Fechamento: </Property>
      <Description>{ format(parseISO(deal.expected_close_date), 'dd/MM/yyyy')}</Description>
      </div>
      <div>
      <Property>Valor: </Property>
      <Description>{Intl.NumberFormat('pt-BR', {style: "currency", currency: "BRL"}).format(deal.value)}</Description>
      </div>
      <h4>Produtos</h4>
      {
        deal.products.map(product=>(
          <div key={product.code}>
            <div>
            <Property>Código: </Property>
            <Description>{product.code}</Description>
            </div>
            <div>
            <Property>Produto: </Property>
            <Description>{product.name}</Description>
            </div>
            <div>
            <Property>Categoria: </Property>
            <Description>{product.category}</Description>
            </div>
            <div>
            <Property>Valor: </Property>
            <Description>{Intl.NumberFormat('pt-BR', {style: "currency", currency: "BRL"}).format(product.value)}</Description>
            </div>
          </div>
        ))
      }
      <Button onClick={()=> navigate( deal.parcels?.length > 0 ? `/deal/update` : `/deal/create`, {state: deal})}>Avançar</Button>
    </Form>
  </Container>
  )
}

export default Detail;